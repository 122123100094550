<template>
    <b-container fluid class="p-0">
      <b-row>
        <b-col cols="12">
          <p id="no-docs-mesg" class="bg-white p-1 font-weight-bold mt-2 mb-3">
            <strong>Please note:</strong>
            <ul class="ml-1 p-0">
              <li class="mt-1 mb-1">If your collection day falls on a bank holiday, please contact us to check if it has been rescheduled</li>
              <li>Please note that collection days are subject to change, however we will endeavour to give you as much notice as possible if this does occur. Collections can be at any time during the day or night so we always recommend you leave your bin out the night before the collection day, unless you have specified different arrangements during the setup process.</li>
            </ul>
          </p>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import { BRow, BCol, BContainer } from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BContainer
  },
  data() {
    return {};
  },
};
</script>