<template>
  <div>
    <!-- <calendar-message-xmas /> -->
    <FullCalendar
      :options="{
        ...calendarOptions,
        events: premiseCollections,
      }"
    />
    <calendar-message />
  </div>
</template>

<script>
import store from "@/store/index";
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import CalendarMessage from "./CalendarMessage.vue";
import CalendarMessageXmas from "./CalendarMessageXmas.vue";

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarMessageXmas,
    CalendarMessage,
  },
  computed: {
    premiseCollections: function () {
      return store.state.app.selectedPremiseCollections;
    },
    machineID: function () {
      return store.state.app.selectedPremiseID;
    },
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: "listYear",
        customButtons: {
          printButton: {
            text: "Print",
            click: () => this.$router.push({ name: "cal-list" }),
          },
          subscribeButton: {
            text: "Add to Cal/Phone",
            click: () =>
              window.open(
                "https://www.betterwaste.co.uk/ical/ical.php?caccid=" +
                  this.machineID
              ),
          },
        },
        headerToolbar: {
          left: "listYear,dayGridMonth printButton subscribeButton",
          center: "title",
          right: "prev,next",
        },
        buttonText: {
          today: "Today",
          month: "Calendar",
          list: "List",
        },
      },
    };
  },
};
</script>
