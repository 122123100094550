<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col cols="12">
        <p id="xmas-msg" class="bg-white p-1 font-weight-bold mt-2 mb-3">
          <strong
            ><span class="text-danger">Please note:</span> If you have made
            changes for Christmas Closures they won't be reflected in the
            calendar below.</strong
          >
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { BRow, BCol, BContainer } from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BContainer,
  },
  data() {
    return {};
  },
};
</script>
